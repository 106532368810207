<template>
  <a-modal
    v-model:visible="visibleModal"
    title="Ajouter video"
    :width="800"
    ok-text="Ajouter"
    @ok="onSubmit"
    @cancel="cancel"
  >
    <a-form
      ref="formRef"
      :model="formState"
      label-align="left"
      class="container"
      :rules="rules"
      :label-col="{ span: 10 }"
      :wrapper-col="{ span: 12 }"
      @finishFailed="handleFinishFailed"      
    >
      <div class="row">
        <div class="col md-6">
          <a-form-item label="Lien" name="link">
            <a-input v-model:value="formState.link" placeholder="Lien"  />
          </a-form-item>
          <a-form-item label="Discription" name="discription">
            <a-input v-model:value="formState.discription" placeholder="discription" />
          </a-form-item>

        </div>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
/* eslint-disable */
import { message } from 'ant-design-vue'
import { ref,toRaw,watch,emits, reactive,computed} from 'vue'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons-vue'
import ApiClient from '@/services/axios'
import { mapState } from 'vuex'
export default {
      components: {
    CheckOutlined,
    CloseOutlined,
  },
  computed: mapState(["user"]),
  emits: ['addedUser','cancel'],
  props: {
     visible:{
      type:Boolean
    }
  },
  setup(props) {
    const formRef = ref();
    const visibleModal = ref(false)

watch(() => props.visible, (first, second) => {
      visibleModal.value=first
    });
      const rules = {
      link: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },

      ],
      discription: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
      ],
    };
    const formState = reactive({
      link: '',
      discription: '',
    })
    
   /* const onSubmit = () => {
      console.log(formRef.value)
      formRef.value
        .validate().then(() => {
          console.log("validated")
        })
    }*/
     const handleFinishFailed = (errors) => {
      console.log(errors)
    }
    return {
      handleFinishFailed,
      visibleModal,
      formState,
      rules,
      formRef,
    }
  },
   methods: {
     cancel(e){
      this.$emit("cancel",false)
    },
     onSubmit(){
       this.formRef
        .validate().then(() => {
                const data = toRaw(this.formState)
      ApiClient.put("/videos",
      {
          'data':data,
      })
      .then((res)=>{
       this.$emit("videoAdded",res.data)
        message.success(`Vidéo ajouté`)
      })
    .catch((e)=>{
        message.warning(e.response.data.message)
      }) 
        })
        .catch(error => {
          console.log('error', error);
        });

            
    },
},
}
</script>
