<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header py-0">
            <vb-headers-card-header-4 :data="headerData" />
          </div>
          <div class="card-body">
            <vb-controls-button-1
              :data="{ title: 'Ajouter un video', icon: 'fe fe-plus-circle', type: 'primary' }"
              @click="addUser"
            />
            <VideoTable :data="dataSource" :loading="tableLoading" />
          </div>
        </div>
      </div>
    </div>
    <addVideoForm
      :visible="visible"
      @videoAdded="videoAdded"
      @cancel="
        () => {
          visible = false
        }
      "
    />
  </div>
</template>

<script>
import VbHeadersCardHeader4 from '@/@vb/widgets/Headers/CardHeader4'
import VideoTable from '@/components/widgets/TablesAntd/videos'
import VbControlsButton1 from '@/@vb/widgets/Controls/Button'
import addVideoForm from '@/components/widgets/Forms/addVideoForm'
import { ref, onBeforeMount, computed } from 'vue'
import apiClient from '@/services/axios'
import { useStore } from 'vuex'

export default {
  name: 'VbComptes',
  components: {
    VbHeadersCardHeader4,
    VideoTable,
    VbControlsButton1,
    addVideoForm,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    const visible = ref(false)
    const tableLoading = ref(true)
    const dataSource = ref([])
    const headerData = ref({
      title: 'Gestion de contenu multimédia',
      icon: 'fe fe-users',
      description: 'Intégrer votre chaine youtube',
      res: {},
    })
    apiClient
      .post('/videos/filter',{
          query:{}
      })
      .then((res) => {
        dataSource.value = res.data
      })
      .catch((err) => console.log(err))
      .finally(() => (tableLoading.value = false))
    const addUser = () => {
      visible.value = !visible.value
    }
    const videoAdded = (newVideo) => {
      dataSource.value.unshift(newVideo)
      headerData.value.res.total = +headerData.value.res.total + 1
      visible.value = false
    }
    return {
      headerData,
      dataSource,
      visible,
      tableLoading,
      addUser,
      videoAdded,
    }
  },
}
</script>
